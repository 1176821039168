@import '../../../assets/scss/BaseStyle/variables';

.app-progressbar {
  .progress {
    background-color: #e4e8ea;
    height: 0.375rem;
  }

  .percentage {
    font-family: $baseReg;
    font-size: 0.688rem;
    color: $gray-500;
  }

  &.primary {
    .progress-bar {
      background-color: $primaryDark;
    }
  }
}
