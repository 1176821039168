@import '../../../assets/scss/BaseStyle/variables';

.app-breadcrumb {
  padding-bottom: 0;
  margin-bottom: 0;

  ol {
    margin-bottom: 0;

    li {
      a {
        color: $primaryLight;
        font-size: $smallFontSize;
        font-family: $baseReg;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: $primaryDark;
        }
      }

      &.active {
        a {
          color: $bodyTextColor;
          font-family: $baseMed;

          &:hover,
          &:focus {
            text-decoration: underline;
            color: $primaryDark;
          }
        }
      }
    }
  }
}
