@import '../BaseStyle/variables';

.modal {
  .modal-content {
    padding: 2.75rem 1.875rem;
    background-color: $gray-300;
    color: $bodyTextColor;
    border: none;
    border-radius: 0;

    .modal-header {
      padding: 0;
      font-family: $baseMed;
      font-size: $mdFontSize;
      border-bottom: none;
    }

    .modal-title {
      font-size: $mdFontSize;
    }

    .modal-body {
      padding: 0.625rem 0 0;
      font-family: $baseReg;
      font-size: $baseFontSize;
    }

    .btn-close {
      position: absolute;
      right: 1.313rem;
      top: 1.313rem;
      font-size: 0.6rem;
      box-shadow: none;
    }
  }

  &.white-bg {
    .modal-content {
      background: $white;
    }
  }

  &.upload-report-modal .modal-dialog {
    max-width: 57.438rem;

    .modal-content {
      background: $white;
    }
  }

  &.job-request-modal .modal-dialog {
    max-width: 43rem;
  }
}
