@import "../BaseStyle/variables";

.form-style {
  .fa-exclamation-circle {
    display: none;
    position: absolute;
    top: 2.5rem;
    right: 0.938rem;
    color: $dangerDark;
  }

  .has-error + svg {
    display: block;
  }

  label,
  .form-label {
    color: $gray-500;
    font-family: $baseMed;
    font-size: $smallFontSize;
    display: block;

    span {
      float: right;
    }

    &.required {
      &::after {
        content: "*";
        color: $dangerDark;
        margin: 0 0.25rem;
      }
    }
  }

  .form-control {
    border: $borderLight;
    border-radius: $border-radius;
    color: $gray-700;
    font-family: $baseReg;
    font-size: $baseFontSize;
    padding: 0.4rem 0.5rem;

    &::placeholder {
      color: $gray-100;
      font-size: $smallFontSize;
      padding: 0 0.25rem;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }

    &.has-error {
      border: $borderLight;
      border-color: $dangerDark;
    }
  }

  .input-search input {
    text-indent: 24px;
  }

  .light-placeholder input::placeholder {
    color: $gray-100;
  }

  .normal-radio {
    &.has-error {
      input {
        border-color: $dangerDark;
      }
    }

    .form-check-label {
      position: relative;
      top: 0.188rem;
      color: $gray-500;
      font-family: $baseReg;
      font-size: $baseFontSize;
    }
  }

  .styled-radio {
    .radio-item {
      margin-right: 0;
      text-align: center;
      border: $borderLight;
      padding: 0;
      border-radius: 0;

      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
        border-right: none;
      }

      &:nth-child(2) {
        border-radius: 0 $border-radius $border-radius 0;
      }

      input[type="radio"] {
        display: none;
      }

      label {
        color: $secondary;
        font-size: $smallFontSize;
        font-family: $baseReg;
        width: 100%;
        padding: 0.5em;
      }

      input:checked[type="radio"] ~ label {
        background-color: $primary;
        color: $white;
      }
    }

    &.has-error {
      .radio-item {
        border-color: $dangerDark;
      }
    }
  }

  .container-flex-end {
    height: 100%;

    div {
      width: 100%;
    }
  }

  .error-message {
    font-size: $smallFontSize;
    font-family: $baseReg;
    color: $dangerDark;
  }

  textarea {
    min-height: 7rem;
  }

  .search-icon {
    position: absolute;
    top: 42px;
    left: 16px;
    color: $gray-100;
  }
}
