@import '../../../assets/scss/BaseStyle/variables';

.badge {
  text-transform: capitalize;
  font-size: $smallFontSize;
  font-family: $baseMed;
  vertical-align: middle;
  cursor: grab;
  margin: 1rem;
  padding: 5px 15px;

  &.bg-light-pending,
  &.bg-light-draft,
  &.bg-light-report_uploaded {
    background: $warningMed;
    color: $warningDark;
    border: $borderWidth solid $warningDark;
  }

  &.bg-light-published,
  &.bg-light-verified {
    background: $successMed;
    color: $successDark;
    border: $borderWidth solid $successDark;
  }

  &.bg-light-not_valid {
    background: $dangerMed;
    color: $dangerDark;
    border: $borderWidth solid $dangerDark;
  }

  &.bg-light-secondary,
  &.bg-light-assigned {
    background-color: $gray-200;
    color: $bodyTextColor;
  }

  &.bg-pending,
  &.bg-not_started,
  &.bg-initiated,
  &.bg-draft,
  &.bg-report_uploaded {
    background: $warningDark;
    color: $white;
    border: $borderWidth solid $warningDark;
  }

  &.bg-published,
  &.bg-verified,
  &.bg-completed {
    background: $successDark;
    color: $white;
    border: $borderWidth solid $successDark;
  }

  &.bg-not_valid,
  &.bg-failed,
  &.bg-cancelled {
    background: $dangerDark;
    color: $white;
    border: $borderWidth solid $dangerDark;
  }

  &.bg-secondary,
  &.bg-assigned {
    background-color: $gray-700;
    color: $white;
  }

  &.bg-light-100 {
    background-color: $gray-100;
    color: $bodyTextColor;
  }

  &.bg-light-draft {
    background: $gray-300;
    border: $borderWidth solid $gray-100;
    color: $gray-500;
  }
}
