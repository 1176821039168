@import '../../../assets/scss/BaseStyle/variables';

.data-view {
  padding: 1rem 0;

  .text-with-icon {
    border-radius: $border-radius;
    padding: 1rem 1rem 0.5rem;
    display: inline-flex;
    width: 99%;
    background: $gray-300;

    svg {
      font-size: 3rem;
      color: $gray-100;
      margin: 0 1rem 0 0;
    }

    img {
      width: 20%;
      margin-right: 1rem;
      position: relative;
      top: 0.3rem;
      height: fit-content;
    }

    p {
      margin-bottom: 0.5rem;

      span {
        display: block;

        &.as-label {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .as-label,
  .show-value {
    font-size: $baseFontSize;
    font-family: $baseReg;
    margin-bottom: 0.3rem;
  }

  .as-label {
    color: $gray-500;
  }

  .show-value {
    color: $bodyTextColor;
  }
}
