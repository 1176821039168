@import '../../../assets/scss/BaseStyle/variables';

div {
  &.attachment {
    text-decoration: none;
    color: $bodyTextColor;
    font-family: $baseMed;
    margin-bottom: 1rem;

    .attachment-item {
      width: 100px;
      height: 100px;
      border: $borderLight;
      display: block;
      border-radius: $border-radius;
      position: relative;

      .fa-file,
      img {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        font-size: 2rem;
      }

      .overlay {
        display: none;
      }

      &:hover {
        .overlay {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: $gray-700;
          text-align: end;
          z-index: 1;

          a {
            margin: 0.2rem;
            padding: 0.3rem 0.4rem;
            border-radius: $border-radius;
            color: $gray-500;
            background: $white;
            position: relative;
            top: 66%;
          }
        }
      }
    }

    .attachment-name,
    .attachment-size {
      width: 100px;
      display: block;
      text-align: center;
      font-size: $smallFontSize;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .attachment-name {
      margin-top: 0.5rem;
      font-family: $baseMed;
      color: $bodyTextColor;
    }

    .attachment-size {
      font-family: $baseReg;
      color: $gray-100;
      margin-bottom: 1rem;
    }
  }
}
