.App,
.main-content {
  min-height: calc(100vh - 143px);
}

.main-card {
  border-radius: $border-radius;
  border: $borderWidth solid $gray-300;
  background: $white;
  position: relative;
  padding: 2rem;
  margin-bottom: 1rem;

  &.make-it-top {
    top: -4rem;
  }
}

.page-head {
  background-color: $gray-200;
  padding-top: 1rem;
  padding-bottom: 5rem;

  &.bottomPad-3 {
    padding-bottom: 3rem;
  }
}

.fa-phone {
  transform: rotate(90deg);
}

.card-shadow {
  box-shadow: $cardShadow;
}

.text-decoration-none a {
  text-decoration: none;
}

.base-med {
  font-family: $baseMed;
}

.w-70 {
  width: 70%;
}

.f-18 {
  font-size: 18px;
}

.w-md-50 {
  @media (min-width: $xlDevices) {
    width: 50%;
  }
}

.border-left-0 {
  border-left: none !important;
}
