@import '../../../assets/scss/BaseStyle/variables';

.modal-content {
  padding: 3rem 5.938rem;

  .modal-title {
    font-family: $baseMed;
    font-size: $mdFontSize;
    color: $gray-500;
  }

  .modal-body {
    font-family: $baseReg;
    font-size: $baseFontSize;
    color: $bodyTextColor;
  }

  img {
    width: 1.9rem;
    height: 1.9rem;
  }
}
