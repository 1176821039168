@import '../../assets/scss/BaseStyle/variables';

.job-request,
.complete-job-request {
  .form-has-error {
    .while-error {
      top: -1.5rem;
    }
  }

  .age {
    .fa-exclamation-circle {
      right: auto;
      left: 45%;

      @media (max-width: $mdDevices) {
        left: 90%;
      }
    }

    .patient-age {
      width: 50%;

      @media (max-width: $mdDevices) {
        width: 100%;
      }
    }

    .age-unit {
      width: 45%;
      margin-left: 1.5rem;

      @media (max-width: $mdDevices) {
        margin-left: 0;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .footer-border-top {
    border-top: 2px solid $gray-300;
  }

  .hint {
    color: #6e6e6e;
    font-size: $smallFontSize;
    margin-left: 2.1rem;
    display: flex;
  }
}
