@import '../../../assets/scss/BaseStyle/variables';

.app-select {
  .css-1s2u09g-control {
    box-shadow: none;

    &:hover {
      border: $borderLight;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .css-1pahdxg-control {
    border: $borderLight;
    box-shadow: none;

    &:hover {
      border: $borderLight;
    }
  }

  .css-qc6sy-singleValue {
    color: $gray-700;
  }
}
